@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'GeneralSans';
  src: url('../public/fonts/GeneralSans-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}


@font-face {
  font-family: 'GoldmanSans';
  src: url('../public/fonts/gs-sans-variable.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GoldmanSerif';
  src: url('../public/fonts/gs-serif-variable.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GoldmanSerif';
  src: url('../public/fonts/gs-serif-variable.woff2') format('woff2');
  font-weight: 300; /* Light weight */
  font-style: normal;
}

/* Normal (400) */
@font-face {
  font-family: 'GoldmanSerif';
  src: url('../public/fonts/gs-serif-variable.woff2') format('woff2');
  font-weight: 400; /* Regular weight */
  font-style: normal;
}

/* Bold (700) */
@font-face {
  font-family: 'GoldmanSerif';
  src: url('../public/fonts/gs-serif-variable.woff2') format('woff2');
  font-weight: 700; /* Bold weight */
  font-style: normal;
}

/* Italic Light (300) */
@font-face {
  font-family: 'GoldmanSerif';
  src: url('../public/fonts/gs-serif-variable.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
}

/* Italic Normal (400) */
@font-face {
  font-family: 'GoldmanSerif';
  src: url('../public/fonts/gs-serif-variable.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'GoldmanSansCd';
  src: url('../public/fonts/GoldmanSansCd_Bd.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'GoldmanSansCd';
  src: url('../public/fonts/GoldmanSansCd_Rg.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Shipporib1';
  src: url('../public/fonts/breu.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}


@font-face {
  font-family: 'Domine';
  src: url('../public/fonts/Domine-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Domine';
  src: url('../public/fonts/DomineMedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Domine';
  src: url('../public/fonts/DomineSemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Domine';
  src: url('../public/fonts/Domine-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}


.para {
  font-family: "Shipporib1", serif;
  line-height: 1.25;
  font-size: 22px;
}

.para2 {
  font-family: "Domine", serif;
  line-height: 1.4;
}